import axios from 'axios'
import { useEffect, useState, useCallback } from 'react'
import useDarkSide from 'utils/useDarkSide'

import BgLight from 'assets/img/bg-splash-light-new.png'
import BgDark from 'assets/img/bg-splash-dark-new.png'
import BgLightMobile from 'assets/img/bg-splash-light-new-mobile.png'
import BgDarkMobile from 'assets/img/bg-splash-dark-new-mobile.png'
// import LogoMedium from 'assets/img/icon-medium-logo.png'
import DarkLogo from 'assets/img/icon-menu-logo-dark.png';
import LightLogo from 'assets/img/icon-menu-logo.png';

import TwitterIcon from 'assets/img/icon-splash-twitter.png'
import MailIcon from 'assets/img/icon-splash-mail.png'
import TwitterDarkIcon from 'assets/img/icon-splash-twitter-dark.png'
import MailDarkIcon from 'assets/img/icon-splash-mail-dark.png'
import EffectBlackSmallIcon from 'assets/img/icon-mobile-dark.png'
import EffectWhiteSmallIcon from 'assets/img/icon-mobile-light.png'
// import IconCheckGreen from "assets/img/icon-check-green.png"
// import IconCheckPink from "assets/img/icon-check-pink.png"

import { validateEmail } from 'utils/Utils'

const baseURL = 'https://emailapi.nf3.exchange/email'

const SplashPage = () => {
  const [theme, setTheme] = useDarkSide()
  const [email, setEmail] = useState('')
  // const [isAccept, setAccept] = useState(false)
  const [isEmail, setEmailValid] = useState(false)
  const [disable, setDisable] = useState(false)

  useEffect(() => {
    setDisable(email !== '')
    setEmailValid(false)
    // setAccept(false)
  }, [email])

  const handleEmail = useCallback((e) => {
    setEmail(e.target.value)
  }, [])

  const apiLogin = useCallback(() => {
    axios.post(`${baseURL}`, {
      email_add: email
    })
      .then(response => {
        // setAccept(true)
        setEmail('')
      })
  }, [email])

  const handleVerify = useCallback(() => {
    if (!disable) return

    if (validateEmail(email)) {
      apiLogin()
    } else {
      setEmailValid(true)
    }

  }, [disable, email, apiLogin])

  return (
    <>
      <div className="w-full bg-white dark:bg-[black] container">
        <div className='py-[20px] px-[32px] hidden md:block'>
          <div className='flex'>
            <div className="w-full h-[40px] flex bg-contain bg-no-repeat" style={{ backgroundImage: `url(${theme === 'dark' ? DarkLogo : LightLogo})` }} />
            <div
              className="h-[42px] w-[42px] ml-auto"
              style={{ backgroundImage: `url(${theme !== 'dark' ? EffectBlackSmallIcon : EffectWhiteSmallIcon})`, backgroundRepeat: "no-repeat", backgroundSize: 'contain' }}
              onClick={() => {
                setTheme(theme === 'dark' ? 'light' : 'dark')
              }} />
          </div>
          <div className='mt-[54px]'>
            <div className='text-[#FF0083] text-[40px] font-nftBoldOther text-center'>Your Gateway to</div>
            <div className='flex justify-center'>
              <div className='text-black w-[80%] dark:text-white text-[72px] font-nftBoldOther text-center'>
                Seamless P2P Swaps and Expert Brokers at Your request!
              </div>
            </div>
          </div>
          <div className='flex justify-center mt-[46px] gap-[17px]'>
            <a href="https://app.nf3.exchange/p2p" rel='noopener noreferrer' target="_blank" className='bg-[#FF0083] text-white rounded-[4px] w-[332px] h-[40px] text-center font-nftBoldOther p-[7px] text-[16px]'>Launch P2P Swaps</a>
            <a href="https://app.nf3.exchange/brokers" rel='noopener noreferrer' target="_blank" className='bg-[#FF0083] text-white rounded-[4px] w-[332px] h-[40px] text-center font-nftBoldOther p-[7px] text-[16px]'>Launch Swap+</a>
          </div>
          <div className='flex justify-center mt-[46px]'>
            <img src={theme === 'dark' ? BgDark : BgLight} alt="splash" className="w-full max-h-[calc(100vh-78px)] object-cover object-center rounded-[20px]" />
          </div>
          <div className='mt-[34px]'>
            <p className='font-nftRegular text-black dark:text-white text-[15px]'>Be the first to get exclusive updates and  latest news about NF3x</p>
            <div className='flex justify-between mt-[20px]'>
              <div className='flex items-center gap-[12px]'>
                <input
                  onChange={handleEmail}
                  value={email}
                  placeholder='Enter your email address'
                  className={`text-[14px] w-[422px] h-[40px] font-nftRegular text-[#8C8C8C] dark:text-white border-[1px] ${isEmail ? 'border-red-700' : 'border-[#BCBCBC] dark:border-[#333]'} p-[15px] rounded-[4px] outline-none bg-white dark:bg-[#1C1C1C]`}
                />
                <div onClick={handleVerify} className={`w-[230px] h-[40px] font-nftBoldOther ${!disable ? 'cursor-not-allowed' : 'cursor-pointer'} bg-[#272727] dark:bg-white h-[48px] rounded-[4px] flex p-[8px]`} >
                  <p className={`m-auto ${disable ? 'text-white dark:text-[#171717]' : 'text-white/50 dark:text-[#171717]/50'} text-[14px]`}>Subscribe</p>
                </div>
              </div>

              <div className='flex justify-between'>
                <div className='flex items-center gap-[12px]'>
                  <a href="https://twitter.com/nf3exchange" rel='noopener noreferrer' target="_blank" className="w-[33px] h-[28px] hover:scale-[1.1] bg-contain bg-no-repeat" style={{ backgroundImage: `url(${theme === 'dark' ? TwitterDarkIcon : TwitterIcon})` }}>
                  </a>
                  <a href="mailto:hello@nf3x.io" rel="noreferrer" className="w-[40px] h-[40px] hover:scale-[1.1] bg-contain bg-no-repeat" style={{ backgroundImage: `url(${theme === 'dark' ? MailDarkIcon : MailIcon})` }}>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='p-[23px] md:hidden'>
          <div className='flex'>
            <div className="w-full h-[40px] flex bg-contain bg-no-repeat" style={{ backgroundImage: `url(${theme === 'dark' ? DarkLogo : LightLogo})` }} />
            <div
              className="h-[42px] w-[42px] ml-auto"
              style={{ backgroundImage: `url(${theme !== 'dark' ? EffectBlackSmallIcon : EffectWhiteSmallIcon})`, backgroundRepeat: "no-repeat", backgroundSize: 'contain' }}
              onClick={() => {
                setTheme(theme === 'dark' ? 'light' : 'dark')
              }} />
          </div>
          <div className='mt-[54px]'>
            <div className='text-[#FF0083] text-[22px] font-nftBoldOther text-center'>Your Gateway to</div>
            <div className='flex justify-center'>
              <div className='text-black w-[80%] dark:text-white text-[32px] font-nftBoldOther text-center'>
                Seamless P2P Swaps and Expert Brokers at Your request!
              </div>
            </div>
          </div>
          <div className='flex flex-col items-center mt-[46px] gap-[17px]'>
            <a href="https://app.nf3.exchange/p2p" rel='noopener noreferrer' target="_blank" className='bg-[#FF0083] text-white rounded-[4px] w-full h-[40px] text-center font-nftBoldOther p-[7px] text-[16px]'>Launch P2P Swaps</a>
            <a href="https://app.nf3.exchange/p2p" rel='noopener noreferrer' target="_blank" className='bg-[#FF0083] text-white rounded-[4px] w-full h-[40px] text-center font-nftBoldOther p-[7px] text-[16px]'>Launch Swap+</a>
          </div>
          <div className='flex justify-center mt-[46px]'>
            <img src={theme === 'dark' ? BgDarkMobile : BgLightMobile} alt="splash" className="w-full max-h-[calc(100vh-78px)] object-cover object-center rounded-[20px]" />
          </div>
          <div className='mt-[34px]'>
            <p className='font-nftRegular text-black dark:text-white text-center text-[15px]'>Be the first to get exclusive updates and  latest news about NF3x</p>
            <div className='flex flex-col justify-between mt-[20px] gap-[12px]'>
              <input
                onChange={handleEmail}
                value={email}
                placeholder='Enter your email address'
                className={`text-[14px] w-full h-[40px] font-nftRegular text-[#8C8C8C] dark:text-white border-[1px] ${isEmail ? 'border-red-700' : 'border-[#BCBCBC] dark:border-[#333]'} p-[15px] rounded-[4px] outline-none bg-white dark:bg-[#1C1C1C]`}
              />
              <div onClick={handleVerify} className={`w-full h-[40px] font-nftBoldOther ${!disable ? 'cursor-not-allowed' : 'cursor-pointer'} bg-[#272727] dark:bg-white h-[48px] rounded-[4px] flex p-[8px]`} >
                <p className={`m-auto ${disable ? 'text-white dark:text-[#171717]' : 'text-white/50 dark:text-[#171717]/50'} text-[14px]`}>Subscribe</p>
              </div>
            </div>

            <div className='flex justify-center items-center gap-[20px] mt-[20px]'>
              <a href="https://twitter.com/nf3exchange" rel='noopener noreferrer' target="_blank" className="w-[33px] h-[28px] hover:scale-[1.1] bg-contain bg-no-repeat" style={{ backgroundImage: `url(${theme === 'dark' ? TwitterDarkIcon : TwitterIcon})` }}>
              </a>
              <a href="mailto:hello@nf3x.io" rel="noreferrer" className="w-[40px] h-[40px] hover:scale-[1.1] bg-contain bg-no-repeat" style={{ backgroundImage: `url(${theme === 'dark' ? MailDarkIcon : MailIcon})` }}>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SplashPage
