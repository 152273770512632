import useDarkSide from "utils/useDarkSide";

import Banner from "components/landing/Banner";
import Swap from "components/landing/Swap";
import SwapBeta from "components/landing/SwapBeta";
import Daylight from "components/landing/Daylight";
import Featured from "components/landing/Featured";
import Swapping from "components/landing/Swapping";
import Footer from "components/landing/Footer";

const LandingPage = () => {
  const [theme] = useDarkSide();

  return (
    <div className="w-full container">
      <Banner theme={theme} />
      <Swap theme={theme} />
      <SwapBeta theme={theme} />
      <Daylight theme={theme} />
      <Featured theme={theme} />
      <Swapping theme={theme} />
      <Footer theme={theme} />
    </div>
  );
};

export default LandingPage;
