import React, { useState, useEffect } from "react";

import IconEthereum from "assets/img/swapbeta-icon-ethereum.png";
import IconPolygon from "assets/img/swapbeta-icon-polygon.png";
import IconEthereum1 from "assets/img/swapbeta-icon-ethereum1.png";
import IconComplete from "assets/img/swapbeta-icon-complete.png";

import axios from "axios";

const collectionList = [
];



const SwapBeta = ({ theme }) => {
  useEffect (() => {
    getInfo();
    getCollections();
  },[]);
  
  const [topInfo, setTopInfo] = useState({ total: 0, nft: 0, eth: 0 });
  
  const getInfo = async () => {
    const response = await axios.get(`https://testp2p-api.nf3.exchange/total-volume`, {
    });
    const data = response?.data;
  
    setTopInfo({
      total: Math.floor(data.trades_count),
      eth: Math.floor(data.eth_volume),
      nft: Math.floor(data.nfts_count),
    });
  };

  const getCollections = async () => {
    const response = await axios.get(`https://testp2p-api.nf3.exchange/kollections`, {
    });
    const data = response?.data?.collections;
    for ( var i = 0 ; i < data.length; i ++) {
      collectionList.push({img : data[i].image , amount : data[i].total_volume.trades_count})
    }

  };

  return (
    <div className="w-full bg-white mt-[141px] mb-[120px] dark:bg-[#1A1429] relative text-black dark:text-white">
      <p className="w-full text-center px-1 mb-[80px] text-[40px] font-nftBoldOther">
        P2P Swap{" "}
        <span className="text-xl text-transparent bg-clip-text bg-gradient-to-r from-[#FF9736] to-[#FF0083]">
          beta
        </span>
      </p>
      <div className="max-w-[1296px] px-4 mx-auto flex flex-col gap-8 xl:gap-0 xl:flex-row items-center xl:items-start justify-between font-nftRegular">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col h-[50px] justify-between">
              <p className="font-nftBoldOther text-2xl leading-[20.66px] font-semibold">
                Our P2P swap protocol is LIVE.
              </p>
              <p className="text-sm leading-[16.8px]">
                Securely swap your NFT(s) peer-to-peer with 0% fees
              </p>
            </div>
            <div className="flex flex-col gap-5 text-sm leading-[21px]">
              <div className="flex gap-4">
                <img src={IconEthereum} alt="ethereum" width={50} height={50} />
                <img src={IconPolygon} alt="polygon" width={50} height={50} />
              </div>
              <p className="font-nftBoldOther font-semibold">
                Cross chain swaps?
              </p>
              <p className="max-w-[540px]">
                No Problem. Easily swap NFTs and tokens across 2 integrated
                chains, Ethereum and Polygon. With more chains to be added soon.
              </p>
            </div>
            <p className="text-xs leading-[18px]">
              Audited by: <span className="text-[#1197E2]">Certik</span> &{" "}
              <span className="text-[#1197E2]">Verichains</span>
            </p>
            <button className="bg-[#FF0083] w-[230px] h-[40px] rounded font-semibold leading-[13.77px] text-white">
              Launch App
            </button>
          </div>
          <div className="flex flex-col gap-8 border border-[#D9D9D9] rounded-lg p-8">
            <div className="flex gap-10 items-center">
              <div className="flex flex-col items-center">
                <img src={IconComplete} alt="complete" width={40} height={40} />
                <p className="mt-5 font-semibold text-sm leading-[21px] text-center">
                  Total Swaps Completed
                </p>
                <p className="mt-2 sm:mt-8 font-semibold text-sm sm:text-[40px] text-[#FF80C1] sm:leading-[60px]">
                  {topInfo.total}
                </p>
              </div>
              <div className="border border-[#D9D9D9] h-[128px]"></div>
              <div className="flex flex-col items-center">
                <img
                  src={IconEthereum1}
                  alt="complete"
                  width={40}
                  height={40}
                />
                <p className="mt-5 font-semibold text-sm leading-[21px] text-center">
                  Total ETH Volume
                </p>
                <p className="mt-2 sm:mt-8 font-semibold text-sm sm:text-[40px] text-[#FF80C1] sm:leading-[60px]">
                  {topInfo.eth} ETH
                </p>
              </div>
            </div>
            <p className="font-semibold text-sm leading-[21px] text-center">
              Most Swapped Collections
            </p>
            <div className="flex gap-10 mx-auto">
              {collectionList.map((collection, index) => {
                return (
                  <div className="flex flex-col items-center gap-3" key={index}>
                    <img
                      src={collection.img}
                      alt={`collection${index}`}
                      width={50}
                      height={50}
                    />
                    <p className="font-semibold text-xl leading-[30px] text-[#FF80C1]">
                      {collection.amount}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
      </div>
    </div>
  );
};

export default SwapBeta;
