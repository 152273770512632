import { useEffect, useState } from "react"
import { AnimateKeyframes } from "react-simple-animate"
import { useInView } from 'react-intersection-observer'

import Bg from 'assets/img/bg-swapping.svg'
import BgDark from 'assets/img/bg-swapping-dark.svg'
import Logo from 'assets/img/icon-app-swapping.png'
import ArrowIcon from 'assets/img/icon-swapping-arrow.png'

import BrownIcon from 'assets/img/icon-swapping-effect-brown.png'
import PinkIcon from 'assets/img/icon-swapping-effect-pink.png'
import YellowIcon from 'assets/img/icon-swapping-effect-yellow.png'

import TestIcon1 from 'assets/img/test-swapping-icon1.png'
import TestIcon1Dark from 'assets/img/test-swapping-icon1-dark.png'
import TestIcon2 from 'assets/img/test-swapping-icon2.png'
import TestIcon2Dark from 'assets/img/test-swapping-icon2-dark.png'
import TestIcon3 from 'assets/img/test-swapping-icon3.png'
import TestIcon3Dark from 'assets/img/test-swapping-icon3-dark.png'
import TestIcon4 from 'assets/img/test-swapping-icon4.png'
import TestIcon4Dark from 'assets/img/test-swapping-icon4-dark.png'
import TestIcon5 from 'assets/img/test-swapping-icon5.png'
import TestIcon5Dark from 'assets/img/test-swapping-icon5-dark.png'
import TestIcon6 from 'assets/img/test-swapping-icon6.png'
import TestIcon6Dark from 'assets/img/test-swapping-icon6-dark.png'
import TestIcon7 from 'assets/img/test-swapping-icon7.png'
import TestIcon7Dark from 'assets/img/test-swapping-icon7-dark.png'

// const getRandomArbitrary = (min, max) => {
//   return Math.random() * (max - min) + min
// }

const Swapping = ({ theme }) => {

  const [isShow, setShow] = useState(false)
  const [isStop, setStop] = useState(false)
  const { ref: myRef, inView: myElementIsVisible } = useInView();

  useEffect(() => {
    const timer = setTimeout(() => {
      setStop(true)
    }, 4000)

    if (myElementIsVisible) {
      setShow(true)
    } else {
      clearTimeout(timer)
    }

  }, [myElementIsVisible])

  const arrImages = [
    { src: theme === 'dark' ? TestIcon1Dark : TestIcon1, style: { width: 160, height: 180 }, duration: 9, position: [[0, 0], [800, 0], [800, 600], [0, 600], [0, 0]] },
    { src: theme === 'dark' ? TestIcon2Dark : TestIcon2, style: { width: 96, height: 111 }, duration: 14, position: [[600, 100], [0, 100], [0, 0], [650, 0], [600, 100]] },
    { src: theme === 'dark' ? TestIcon3Dark : TestIcon3, style: { width: 100, height: 115 }, duration: 7, position: [[800, 50], [0, 50], [0, 0], [800, 0], [800, 50]] },
    { src: theme === 'dark' ? TestIcon4Dark : TestIcon4, style: { width: 150, height: 174 }, duration: 11, position: [[0, 100], [550, 100], [550, 400], [0, 400], [0, 100]] },
    { src: theme === 'dark' ? TestIcon5Dark : TestIcon5, style: { width: 150, height: 174 }, duration: 19, position: [[600, 30], [0, 30], [0, 0], [600, 0], [600, 30]] },
    { src: theme === 'dark' ? TestIcon6Dark : TestIcon6, style: { width: 150, height: 174 }, duration: 6, position: [[700, 300], [0, 300], [0, 0], [700, 0], [700, 300]] },
    { src: theme === 'dark' ? TestIcon7Dark : TestIcon7, style: { width: 112, height: 130 }, duration: 15, position: [[750, 330], [0, 330], [0, 0], [750, 0], [750, 330]] }
  ]

  return (
    <div ref={myRef} className="w-full grid grid-cols-6 gap-4 h-[916px] relative bg-white dark:bg-[#1A1429]">
      <img src={theme === 'dark' ? BgDark : Bg} alt="landing-daylight" className="m-auto hidden md:flex absolute h-full w-full object-contain object-center" />
      <div className='w-full h-full absolute md:flex hidden'>
        <div className='m-auto flex'>
          <div className='animate-pulse w-[60px] h-[60px] mt-[240px] ml-[0px] relative'>
            <div className='w-full h-full animate-ping bg-[#3CD7D7] rounded-full'></div>
            <img src={YellowIcon} alt="brown" className="w-full h-full absolute top-0" />
          </div>
          <div className='animate-pulse w-[60px] h-[60px] mt-[88px] ml-[50px] relative'>
            <div className='w-full h-full animate-ping bg-[#A995F5] rounded-full'></div>
            <img src={PinkIcon} alt="brown" className="w-full h-full absolute top-0" />
          </div>
          <div className='animate-pulse w-[60px] h-[60px] mt-[136px] ml-[500px] relative'>
            <div className='w-full h-full animate-ping bg-[#A995F5] rounded-full'></div>
            <img src={BrownIcon} alt="brown" className="w-full h-full absolute top-0" />
          </div>
        </div>
      </div>
      <div className='hidden md:flex w-full h-full absolute'>
        <div className="m-auto w-[1000px] h-[700px] flex swapping">
          {arrImages.map((item, index) => {
            return <AnimateKeyframes
              key={index}
              play={isShow}
              pause={isStop}
              iterationCount="1"
              duration={item.duration}
              keyframes={[
                `transform: translate(${item.position[0][0]}px, ${item.position[0][1]}px)`,
                `transform: translate(${item.position[1][0]}px, ${item.position[1][1]}px)`,
                `transform: translate(${item.position[2][0]}px, ${item.position[2][1]}px)`,
                `transform: translate(${item.position[3][0]}px, ${item.position[3][1]}px)`,
                `transform: translate(${item.position[4][0]}px, ${item.position[4][1]}px)`
              ]}
            >
              <img className={`${index % 2 === 1 ? 'pluse' : 'pluse-two'} ${'duration-' + (item.duration - 1)}`} src={item.src} alt='TestIcon1' style={item.style} />
            </AnimateKeyframes>
          })}
        </div>
      </div>
      <div className='col-start-2 col-span-4 m-auto z-10'>
        <div className='flex'>
          <img src={Logo} alt="landing-daylight" className="m-auto h-[320px] min-w-[178px] object-contain object-center" />
        </div>
        <p className='text-[40px] font-nftBoldOther text-center dark:text-white'>Start Swapping Now</p>
        <p className='text-[14px] text-[#5B5B5B] dark:text-[#C4C4D4] text-center font-nftRegular'>A swaps & options platform to barter, reserve &<br />exchange your favourite NFT collections.</p>
        <div className='flex'>
          <div className='m-auto bg-[#FF0083] hover:bg-[#ff50aa] mt-[52px] px-[24px] py-[15px] rounded-md flex cursor-pointer'>
            <p className='uppercase text-white text-[14px] font-nftBold'>launch app&nbsp;</p>
            <img src={ArrowIcon} alt="icon-landing-featured" className="object-center object-contain" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Swapping
