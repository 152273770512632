import Icon from "assets/img/icon-featured.png";
import IconDark from "assets/img/icon-featured-dark.png";
import ArrowIcon from "assets/img/icon-featured-arrow.png";
import ArrowIconDark from "assets/img/icon-featured-arrow-dark.png";
// import TestIcon from "assets/img/test-icon-featured.png";
// import TestIconDark from "assets/img/test-icon-featured-dark.png";

const FeaturedItemCollection = ({ data, theme }) => {
  return (
    <div
      className="relative w-[405px] h-[413px] rounded-xl border-[1px] border-black dark:border-[#6B6381] bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${data.img})` }}
    >
      <div
        className="absolute object-center w-[139px] h-[119px] left-[140px] top-[-28px] bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url(${theme === "dark" ? IconDark : Icon})`,
        }}
      />
      <img
        src={theme === "dark" ? data.darkicon : data.lighticon}
        alt="app"
        className="absolute object-center object-none w-[119px] h-[119px] left-[158px] top-[-28px]"
      />
      <div className="w-full bottom-[23px] absolute flex">
        <div className="m-auto flex border-[2px] hover:border-[#ff50aa] dark:hover:border-[#ff50aa] border-black dark:border-[#6B6381] rounded-xl py-[18px] px-[24px] bg-white dark:bg-[#1F1831] cursor-pointer">
          <p className="text-[16px] font-nftBoldOther dark:text-white">
            {data.name}
          </p>
          <p className="text-[16px] font-nftRegular dark:text-white">
            &nbsp;collection&nbsp;
          </p>
          <div
            className="w-[13px] h-[13px] mt-[6px] bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${
                theme === "dark" ? ArrowIconDark : ArrowIcon
              })`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FeaturedItemCollection;
