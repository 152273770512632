import useDarkSide from 'utils/useDarkSide'
import { Link } from "react-router-dom"

const NotFound = () => {
  const [,] = useDarkSide()

  return (
    <div className="w-full h-[100vh] bg-white dark:bg-[#1A1429] container">
      <div className='text-center font-nftBoldOther text-[50px] text-black dark:text-white pt-[45vh]'>
        Oops!
        <p className='text-[20px] font-nftBoldOther'>Something went wrong</p>
      </div>
      <div className='w-full flex'>
        <Link to="/" className="m-auto">
          <button
            className="text-black dark:text-white font-nftBoldOther mt-7 underline">
            Go Home
          </button>
        </Link>
      </div>
    </div>
  )
}

export default NotFound
