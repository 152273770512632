import IconKnow from "assets/img/icon-daylight-know.svg";
import IconCollection from "assets/img/icon-daylight-collection.svg";
import IconMultiple from "assets/img/icon-daylight-multiple.svg";
import IconWallet from "assets/img/icon-daylight-wallet.svg";

const FeaturedItem = ({ data, index }) => {
  return (
    <>
      <div className="flex rounded-md relative min-h-full border-[1px] bg-white dark:bg-[#1F1831] border-[#D9D9D9] dark:border-[#6B6381]">
        <div
          className="absolute object-center w-[52px] h-[45px] left-[30px] top-[-12px] bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url(${
              index === 0
                ? IconKnow
                : index === 1
                ? IconWallet
                : index === 2
                ? IconCollection
                : IconMultiple
            })`,
          }}
        />
        <div className="px-[24px]">
          <p className="font-nftBoldOther pt-[64px] pb-[8px] text-black dark:text-white leading-none text-[20px] uppercase">
            {data.title}
          </p>
          <p className="font-nftRegular pb-[50px] text-[#0C0C0D] dark:text-[#C4C4D4] leading-4 text-[14px] font-medium">
            {data.content}
          </p>
        </div>
      </div>
    </>
  );
};

export default FeaturedItem;
