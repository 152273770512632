import React, { useState, useEffect } from "react";
import useDarkSide from "utils/useDarkSide";

import Bg from "assets/img/bg-landing-vector.png";
// import BgTest1Dark from 'assets/img/test-swap-dark-card1.png'
// import BgTest2Dark from 'assets/img/test-swap-dark-card2.png'
// import BgTest3Dark from 'assets/img/test-swap-dark-card3.png'
// import BgTest4Dark from 'assets/img/test-swap-dark-card4.png'

import BgTest1Dark from "assets/img/swap-card1.svg";
import BgTest2Dark from "assets/img/swap-card2.svg";
import BgTest3Dark from "assets/img/swap-card3.svg";
import BgTest4Dark from "assets/img/swap-card4.svg";

// import IconExchange from "assets/img/ic_exchange.png";
// import IconExchangeActive from "assets/img/ic_exchange_active.png";
// import IconEventExchange from "assets/img/ic_event_exchange.png";
// import IconEventExchangeActive from "assets/img/ic_event_exchange_active.png";
// import IconImage from "assets/img/ic_image.png";
// import IconImageActive from "assets/img/ic_image_active.png";
// import IconSchedule from "assets/img/ic_schedule.png";
// import IconScheduleActive from "assets/img/ic_schedule_active.png";

import IconSwapNow from "assets/img/swapping-swapnow-icon.png";
import IconRNSL from "assets/img/swapping-rnsl-icon.png";
import IconConsidering from "assets/img/swapping-considering-icon.png";
import IconActiveReservations from "assets/img/swapping-activereservations-icon.png";

import "assets/styles/landing.css";

const Swap = () => {
  const [theme] = useDarkSide();

  const arrList = [
    {
      title: "swap now",
      source: [IconSwapNow, IconSwapNow],
      content:
        "Swap instantly with others if the swap now parameters are matched. Parameters may include specific NFT #s from a collection, (any) NFT from a collection, an amount of token, or any combination of the above.",
    },
    {
      title: "rnsl",
      source: [IconRNSL, IconRNSL],
      content:
        "Reserve Now, Swap Later. Pay the reserve price to reserve an NFT or bundle of assets. To exercise the option and take delivery of the NFT or bundle of assets, pay the final swap price before the expiry date.",
    },
    {
      title: "considering",
      source: [IconConsidering, IconConsidering],
      content:
        "When listing your NFT or bundle of assets for swap, indicate to others which NFT collections, traits and tokens you are also interested in and would consider in swap now offers to you.",
    },
    {
      title: "active reservations",
      source: [IconActiveReservations, IconActiveReservations],
      content:
        "Options become active once the initial option price has been paid to the seller. Option holders can choose to pay the swap price and take delivery, sell the option by setting a new option price/accepting an offer, or letr the option expire.",
    },
  ];

  const [select, setSelect] = useState(0);
  const [index, setIndex] = useState(-1);
  const [click, setClick] = useState(false);

  useEffect(() => {
    let timer, keep;

    if (click) {
      clearInterval(timer);

      keep = setInterval(() => {
        setClick(false);
      }, 3000);
    } else {
      clearInterval(keep);

      timer = setInterval(() => {
        setIndex((content) => content + 1);
      }, 2500);
    }

    return () => {
      clearInterval(timer);
      clearInterval(keep);
    };
  }, [click]);

  useEffect(() => {
    if (index !== -1) {
      setSelect((content) => {
        check(content === 3 ? 0 : content + 1);
        return content === 3 ? 0 : content + 1;
      });
    }
  }, [index]);

  const resetCards = (index) => {
    setClick(true);
    setSelect(index);
  };

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const check = (index) => setSelectedIndex(index);

  return (
    <div className="w-full bg-white dark:bg-[#1A1429] relative">
      <img
        src={Bg}
        alt="landing-swap"
        className="absolute object-center right-0 opacity-60 dark:opacity-10"
      />
      <p className="w-full text-center px-1 pt-[86px] pb-[65px] text-[40px] font-nftBoldOther text-black dark:text-white">
        NF3 Swap Cards
      </p>
      <div className="md:grid md:grid-cols-2 md:gap-8 md:max-w-[1440px] pb-[80px] mx-auto">
        <div className="md:col-span-1 md:flex hidden">
          <div className="ml-[320px] m-auto">
            <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
              <div className="md:w-1/2 md:mb-0 mb-6 flex flex-col text-center items-center">
                <section
                  id="slider"
                  className="w-16 h-20 inline-flex items-center justify-center mb-5 flex-shrink-0"
                >
                  <input
                    type="radio"
                    name="slider"
                    id="s1"
                    readOnly
                    checked={selectedIndex === 0}
                    onClick={() => {
                      check(0);
                    }}
                  />
                  <input
                    type="radio"
                    name="slider"
                    id="s2"
                    readOnly
                    checked={selectedIndex === 1}
                    onClick={() => check(1)}
                  />
                  <input
                    type="radio"
                    name="slider"
                    id="s3"
                    readOnly
                    checked={selectedIndex === 2}
                    onClick={() => check(2)}
                  />
                  <input
                    type="radio"
                    name="slider"
                    id="s4"
                    readOnly
                    checked={selectedIndex === 3}
                    onClick={() => check(3)}
                  />
                  <label htmlFor="s1" id="slide1">
                    <img
                      src={theme === "dark" ? BgTest1Dark : BgTest1Dark}
                      alt="swap card"
                      className="w-full h-full landing-card-tilt object-cover object-center selector"
                      onClick={() => {
                        resetCards(0);
                      }}
                    />
                  </label>
                  <label htmlFor="s2" id="slide2">
                    <img
                      src={theme === "dark" ? BgTest2Dark : BgTest2Dark}
                      alt="swap card"
                      className="w-full h-full landing-card-tilt object-cover object-center selector"
                      onClick={() => {
                        resetCards(1);
                      }}
                    />
                  </label>
                  <label htmlFor="s3" id="slide3">
                    <img
                      src={theme === "dark" ? BgTest3Dark : BgTest3Dark}
                      alt="swap card"
                      className="w-full h-full landing-card-tilt object-cover object-center selector"
                      onClick={() => {
                        resetCards(2);
                      }}
                    />
                  </label>
                  <label htmlFor="s4" id="slide4">
                    <img
                      src={theme === "dark" ? BgTest4Dark : BgTest4Dark}
                      alt="swap card"
                      className="w-full h-full landing-card-tilt object-cover object-center selector"
                      onClick={() => {
                        resetCards(3);
                      }}
                    />
                  </label>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div className="md:col-span-1 p-8">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 card-swap">
            {arrList.map((item, i) => {
              return select === i ? (
                <div
                  key={i}
                  className="group scale-105 ease-in-out duration-[1s] cursor-pointer min-h-[264px] rounded-md border-[2px] border-black dark:border-[#6B6381] bg-[#f3f3f3] dark:bg-[#1A1429] px-[24px] py-[20px] relative"
                >
                  <img
                    src={item.source[1]}
                    alt="swap"
                    className="ease-in duration-500 w-[45px] h-[45px] object-center absolute right-[10px] top-0"
                  />
                  <p className="ease-in duration-500 text-[16px] font-nftBoldOther text-[#000000] dark:text-white pb-[5px] uppercase">
                    {item.title}
                  </p>
                  <p className="ease-in duration-500 text-[14px] font-nftRegular text-[#000000] dark:text-[#C4C4D4]">
                    {item.content}
                  </p>
                </div>
              ) : (
                <div
                  key={i}
                  className="group cursor-pointer min-h-[264px] rounded-md border-[2px] border-[#f3f3f350] dark:border-[#6B638150] bg-[#f3f3f350] dark:bg-[#1A142950] px-[24px] py-[20px] relative"
                >
                  <img
                    src={item.source[0]}
                    alt="swap"
                    className="w-[45px] h-[45px] object-center opacity-50 absolute right-[10px] top-0"
                  />
                  <p className="text-[16px] font-nftBoldOther text-[#00000050] dark:text-[#ffffff50] pb-[5px] uppercase">
                    {item.title}
                  </p>
                  <p className="text-[14px] font-nftRegular text-[#00000050] dark:text-[#C4C4D450]">
                    {item.content}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Swap;
