import Bg from 'assets/img/bg-landing-daylight.png'
import BgDark from 'assets/img/bg-landing-daylight-dark.png'

const Daylight = ({theme}) => {

  return (
    <div className="w-full">
        <img src={theme === 'dark' ? BgDark : Bg} alt="landing-daylight" className="w-full object-center object-contain" />
    </div>
  )
}

export default Daylight
