import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"

import "assets/styles/index.css"
import 'animate.css'
import LandingPage from 'views/LandingPage'
import SplashPage from 'views/SplashPage'
// import ExplorerPage from 'views/ExplorerPage'
// import CollectionsPage from 'views/CollectionsPage'
// import CollectionDetailPage from 'views/CollectionDetailPage'
import NotFound from 'views/NotFound'
// import Header from 'components/header/Header'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" index element={<SplashPage />} />
      <Route path="/landing" element={<LandingPage />} />
      <Route path="/referrallink/:id" element={<LandingPage />} />
      {/* <Route path="/explorer" element={<><Header /><ExplorerPage /></>} />
      <Route path="/collections" element={<><Header /><CollectionsPage /></>} />
      <Route path="/collections/:collectionID" element={<><Header /><CollectionDetailPage /></>} /> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
