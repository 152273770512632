import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import FeaturedItem from "components/landing/FeaturedItem";
import FeaturedItemCollection from "components/landing/FeaturedItemCollection";
import { AnimationOnScroll } from "react-animation-on-scroll";

import AzukiCollectionImg from "assets/img/azuki-collection.png";
import DeGodsCollectionImg from "assets/img/degods-collection.png";
import DoodlesCollectionImg from "assets/img/doodles-collection.png";

import AzukiCollectionIcon from "assets/img/azuki-collectin-icon.png";
import DeGodsCollectionIconLight from "assets/img/degods-collection-icon.png";
import DeGodsCollectionIconDark from "assets/img/degods-collection-icon-dark.png";
import DoodlesCollectionIcon from "assets/img/doodles-collection-icon.png";

const featureList = [
  {
    title: "know your swap",
    content:
      "Swap with confidence knowing only approved NFT collections & tokens are permitted on the exchange.",
  },
  {
    title: "Wallet-to-Wallet chat",
    content:
      "Use our integrated chat facilities to barter and negotiate swaps and option to swap terms with prospective counterparties.",
  },
  {
    title: "collection/trait offers",
    content: "Make NFT collection wide swap now or option to swap offers.",
  },
  {
    title: "multiple offers",
    content:
      "Select multiple NFTs to offer the same swap now, or option to swap later at once. Once an offer is accepted, all other offers are cancelled.",
  },
];

const collectionList = [
  {
    name: "Doodles",
    img: DoodlesCollectionImg,
    darkicon: DoodlesCollectionIcon,
    lighticon: DoodlesCollectionIcon,
  },
  {
    name: "AzuKi",
    img: AzukiCollectionImg,
    darkicon: AzukiCollectionIcon,
    lighticon: AzukiCollectionIcon,
  },
  {
    name: "DeGods",
    img: DeGodsCollectionImg,
    darkicon: DeGodsCollectionIconDark,
    lighticon: DeGodsCollectionIconLight,
  },
  {
    name: "Doodles",
    img: DoodlesCollectionImg,
    darkicon: DoodlesCollectionIcon,
    lighticon: DoodlesCollectionIcon,
  },
  {
    name: "AzuKi",
    img: AzukiCollectionImg,
    darkicon: AzukiCollectionIcon,
    lighticon: AzukiCollectionIcon,
  },
  {
    name: "DeGods",
    img: DeGodsCollectionImg,
    darkicon: DeGodsCollectionIconDark,
    lighticon: DeGodsCollectionIconLight,
  },
];

const Featured = ({ theme }) => {
  const [countShow, setCountShow] = useState(
    (window.innerWidth >= 2560 ? 2560 : window.innerWidth) / 435
  );

  useEffect(() => {
    function handleResize() {
      setCountShow(
        (window.innerWidth >= 2560 ? 2560 : window.innerWidth) / 435
      );
    }

    window.addEventListener("resize", handleResize);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: countShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    speed: 3000,
    cssEase: "linear",
  };

  return (
    <div className="w-full bg-[#F8F8F8] dark:bg-[#1d1630] pb-[170px] rounded-bl-[120%_30%] rounded-br-[120%_30%] rounded-tl-[120%_30%] rounded-tr-[120%_30%]">
      <p className="w-full text-center pt-[120px] pb-[30px] text-[40px] font-nftBoldOther text-black dark:text-white">
        Featured Collections
      </p>
      <Slider {...settings}>
        {collectionList.map((item, index) => {
          return (
            <div
              key={index}
              className="snap-center flex-none px-[15px] pt-[92px] pb-[10px] cursor-pointer"
            >
              <FeaturedItemCollection data={item} theme={theme} />
            </div>
          );
        })}
      </Slider>
      <p className="w-full text-center mt-[100px] pt-[70px] pb-[30px] text-[40px] font-nftBoldOther text-black dark:text-white">
        Exchange Features
      </p>
      <div className="grid px-20 grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 mt-[50px]">
        {featureList.map((item, index) => {
          return (
            <AnimationOnScroll
              key={index}
              animateIn="animate__zoomIn"
              duration={1}
            >
              <div className="group md:p-5 h-full">
                <FeaturedItem data={item} index={index} />
              </div>
            </AnimationOnScroll>
          );
        })}
      </div>
    </div>
  );
};

export default Featured;
