import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import DaylightBanner from "assets/img/bg-splash-light.png";
import DaylightBannerDark from "assets/img/bg-splash-dark.png";

const Banner = ({ theme }) => {
  return (
    <>
      <div className="w-full relative flex justify-center">
        <img
          src={theme === "dark" ? DaylightBannerDark : DaylightBanner}
          alt="landing-banner"
          className="w-full h-[600px] lg:max-xl:h-[60vw] xl:h-[calc(100vh-40px)] object-center xl:max-3xl:object-cover lg:max-xl:object-fill object-cover rounded-bl-[180%_100%] rounded-br-[180%_100%]"
        />
        <AnimationOnScroll
          offset={0}
          delay={300}
          animateIn="animate__fadeIn"
          className="z-10 absolute -bottom-10"
        >
          <div className="lg:w-[658px] sm:w-[64vw] w-[calc(100vw-40px)] px-4 py-4 xl:py-10 shadow-lg dark:text-white text-black bg-white dark:bg-[#1A1429] border-black dark:border-[#6B6381] border rounded-[4px]">
            <div className="mx-auto flex flex-col items-center text-center">
              <p className="font-nftBoldOther text-xl xl:text-[32px] leading-[40px]">
                Barter. Reserve. Swap.
              </p>
              <p className="mt-3 xl:mt-[35px] font-nftRegular text-sm leading-[16.8px] max-w-[533px]">
                A multi-asset swaps & options platform to barter, reserve &
                exchange your favourite NFT collections, entirely peer-to-peer.
              </p>
              <button className="mt-2 xl:mt-10 bg-[#FF0083] w-[230px] h-[40px] rounded font-semibold leading-[13.77px] text-white">
                Launch App
              </button>
            </div>
          </div>
        </AnimationOnScroll>
      </div>
      <div className="relative mx-auto mt-[140px] mb-[40px] w-[360px] h-[1px] bg-[#000000] dark:bg-[#6B6381] flex justify-center items-center">
        <div className="z-10 absolute w-10 h-10 rounded-full bg-white dark:bg-[#1A1429] dark:text-white text-black border-black dark:border-[#6B6381] border flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default Banner;
