import IconLogo from "assets/img/icon-menu-logo.png";
import IconLogoDark from "assets/img/icon-menu-logo-dark.png";
import IconDiscord from "assets/img/icon-discord.png";
import IconTwitter from "assets/img/icon-twitter.png";
import IconMail from "assets/img/icon-mail.png";
import IconDiscordDark from "assets/img/icon-splash-discord.png";
import IconTwitterDark from "assets/img/icon-splash-twitter.png";
import IconMailDark from "assets/img/icon-splash-mail.png";

const Footer = ({ theme }) => {
  return (
    <div className="w-full relative overflow-hidden border-t-[2px] border-[#D9D9D9] dark:border-[#6B6381] container p-8 text-black dark:text-white mb-[110px]">
      <div className="flex xl:flex-row flex-col justify-between items-center gap-5 xl:gap-0">
        <div className="flex xl:justify-start w-full xl:w-auto justify-between items-center gap-5 xl:gap-8">
          <img
            src={theme === "dark" ? IconLogoDark : IconLogo}
            alt="logo"
            width={72}
            height={40}
          />
          <p className="font-nftRegular text-xs">
            A multi-asset swaps platform to barter, reserve &<br />
            exchange your favourite NFT collections, entirely peer-to-peer.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row gap-5 lg:gap-20 text-xs font-medium">
          <div className="flex gap-5 lg:gap-20">
            <div>
              <p>About NF3x</p>
              <p className="mt-3">Docs & Guides</p>
            </div>
            <div>
              <p>Terms of Service</p>
              <p className="mt-3">Contact Us</p>
            </div>
            <div>
              <p>Audit Report</p>
            </div>
          </div>

          <div className="flex gap-5 self-center">
            <img
              src={theme === "dark" ? IconDiscordDark : IconDiscord}
              alt="discord"
              className="w-6 h-6"
            />
            <img
              src={theme === "dark" ? IconTwitterDark : IconTwitter}
              alt="discord"
              className="w-6 h-6"
            />
            <img
              src={theme === "dark" ? IconMailDark : IconMail}
              alt="discord"
              className="w-6 h-6"
            />
          </div>
        </div>
      </div>
      <div className="mt-10 flex justify-between w-full gap-5">
        <p className="font-nftRegular text-xs">
          nf3.exchange | © NF3 2023, All rights reserved
        </p>
        <p className="font-nftRegular text-xs">
          Audited by: <span className="text-[#1197E2]">Certik</span> &{" "}
          <span className="text-[#1197E2]">Verichains</span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
